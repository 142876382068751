import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: "/",
    redirect: "/home/home"
},
{
    path: '/home',
    name: "home",
    component: () =>import("@/views/Home/Home.vue"),
    children: [{
        path: "/home",
        redirect: "/home/home"
    }, {
        path: '/home/home',
        name: 'home',
        component: () =>import("@/views/Home/YhsHome/YhsHome.vue")
    },
    {
        path: '/home/project',
        name: 'project',
        component: () => import("@/views/Home/YhsProject/YhsProject.vue"),
        // children: [{
        //     path: '/home/home/project/ourProjects',
        //     name: 'ourProjects',
        //     component: () => import("@/views/Home/YhsProject/YhsOurProjects/YhsOurProjects.vue")
        // }]
    },
    {
        path: '/home/capital',
        name: 'capital',
        component: () =>import("@/views/Home/YhsCapital/YhsCapital.vue")
    },
    {
        path: '/home/aboutUs',
        name: 'aboutUs',
        component: () =>import('@/views/Home/YhsAboutUs/YhsAboutUs.vue')
    },
    {
        path: '/home/meetOurTeam',
        name: 'meetOurTeam',
        component: () =>import('@/views/Home/YhsMeetOurTeam/YhsMeetOurTeam.vue')
    },
    {
        path: '/home/contact',
        name: 'contact',
        component: () =>import('@/views/Home/YhsContact/YhsContact.vue')
    }
    ]
},
{
    path: '/home/home/project/ourProjects',
    name: 'ourProjects',
    component: () => import("@/views/Home/YhsProject/YhsOurProjects/YhsOurProjects.vue"),
    children: [{
        path: "/home/home/project/ourProjects",
        redirect: "/home/home/project/ourProjects/our"
    }, 
    {
        path: '/home/home/project/ourProjects/our',
        name: 'OurProject',
        component: () =>import("@/views/Home/YhsProject/YhsOurProjects/OurProjects/OurProjects.vue")
    },
    {
        path:"/home/home/project/ourProjects/ellerton",
        name:'ellerton',
        component:()=>import("@/views/Home/YhsProject/YhsOurProjects/Ellerton/Ellerton.vue")
    },
    {
        path:"/home/home/project/ourProjects/nunawading",
        name:'nunawading',
        component:()=>import('@/views/Home/YhsProject/YhsOurProjects/Nunawading/Nunawading.vue')
    },
    {
        path:"/home/home/project/ourProjects/whelersHill",
        name:"whelersHill",
        component:()=>import('@/views/Home/YhsProject/YhsOurProjects/WhelersHill/WhelersHill.vue')
    },
    {
        path:"/home/home/project/ourProjects/autun",
        name:"autun",
        component:()=>import("@/views/Home/YhsProject/YhsOurProjects/Autun/Autun.vue")
    },
    {
        path:"/home/home/project/ourProject/theCooper",
        name:"theCooper",
        component:()=>import("@/views/Home/YhsProject/YhsOurProjects/TheCooper/TheCooper.vue")
    },
    {
        path:"/home/home/project/ourProject/wattlePlace",
        name:"wattlePlace",
        component:()=>import('@/views/Home/YhsProject/YhsOurProjects/WattlePlace/WattlePlace.vue')
    },
    {
        path:"/home/home/project/ourProject/carfigan",
        name:"carfigan",
        component:()=>import('@/views/Home/YhsProject/YhsOurProjects/Carfigan81/Carfigan81.vue')
    }
    
    ]
}

]

const router = new VueRouter({
    routes
})

export default router